.full-width-image {
    width: 100%;
    position: relative;

}

.full-width-image img {
    width: 100%;
}
.deskripsi{
    text-align: center;
    padding: 25px 5px;
    font-size: larger;
}
.padding-bottom20 {
    padding-bottom: 20px;
}
.margin-5{
    margin: 5px;
}