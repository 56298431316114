body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121!important;
  color: #e0e0e0!important;
  padding-top: 4.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffbf61!important;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #ffbf61!important;
}
.navbar-dark .navbar-brand {
  color: #ffbf61!important;
}
a {
  color: #ffbf61!important;
}
.pd-top10{
  padding-top: 10px;
}
.color1{
  color: #6f94ff;
}