.footer {
  padding: 20px 0;
  color: #999;
  background-color: #343a40;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer p {
  font-size: 13px;
  margin-bottom: 0;
}

.copyrights-col-left {
  color: #fff;
}

.copyrights-col-right {
  margin-right: 10px;
  font-size:14px;
}

.copyrights-col-right>a {
  color: #dadada;
}

.copyrights-col-right>a:hover {
  color: #ffbf61;
}

@media (max-width: 767px) {
  .copyrights-col-right {
    text-align: center;
  }

  .copyrights-col-left {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .copyrights-col-right {
    text-align: right;
  }

  .copyrights-col-left {
    text-align: left;
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {}